<template>
  <a-spin :spinning="spinning">
    <div :class="{disabled: disabled}">
      <a-form-model layout="vertical" :model="form"
                    :rules="rules" ref="form">
        <a-card class="card" title="业务信息" :bordered="false">
          <div slot="extra">
            <a-affix :offsetTop="50" :style="{ position: 'absolute', top: '15px', left: 0, width: '98%', 'text-align': 'right'}">
              <a-button><router-link :to="{ name: 'Railway'}">取消</router-link></a-button>
              <a-divider type="vertical" />
              <a-button :disabled="disabled ? true : false" type="primary" @click="handleSubmit" v-preventReClick>保存</a-button>
            </a-affix>
          </div>
          <a-row :gutter="20">
            <a-col v-bind="colSpan">
              <a-form-model-item label="订单编号">
                <a-input v-model="form.serial_num" disabled placeholder="保存后自动生成"/>
              </a-form-model-item>
            </a-col>
            <a-col v-bind="colSpan">
              <a-form-model-item label="报价编号" prop="quota_num">
                <a-input v-model="form.quota_num" />
              </a-form-model-item>
            </a-col>
            <a-col v-bind="colSpan">
              <a-form-model-item label="委托单位">
                <a-select
                  showSearch
                  label-in-value
                  :filter-option="false"
                  allowClear
                  placeholder="请选择委托单位"
                  style="width: 100%"
                  :value="customerInfo"
                  :not-found-content="fetching ? undefined : null"
                  @search="getDepartmentCustomer"
                  @change="changeCustomerInfo"
                >
                  <template v-if="fetching" #notFoundContent>
                    <a-spin size="small" />
                  </template>
                  <a-select-option v-for="op in agencyOps" :key="op.value">{{ op.name }}</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row :gutter="20">
            <a-col v-bind="colSpan">
              <a-form-model-item label="客户业务编号">
                <a-input v-model="form.customer_num"/>
              </a-form-model-item>
            </a-col>
            <a-col v-bind="colSpan">
              <a-form-model-item label="贸易条款">
                <a-auto-complete
                  v-model="form.trade_term"
                  style="width: 100%"
                  placeholder="请选择贸易条款"
                  :filterOption="handleAutoSearch"
                >
                  <template slot="dataSource">
                    <a-select-option v-for="op in tradeTermOps" :key="op.name">{{ op.name }}</a-select-option>
                  </template>
                </a-auto-complete>
              </a-form-model-item>
            </a-col>
            <a-col v-bind="colSpan">
              <a-form-model-item label="拖运类型">
                <a-radio-group :defaultValue="1" v-model="form.trans_type">
                  <a-radio :value="1">整箱</a-radio>
                  <a-radio :value="2">拼箱</a-radio>
                </a-radio-group>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row :gutter="20">
            <a-col :xxl="14" :lg="16" :md="18">
              <a-form-model-item label="服务类型" prop="service_types">
                <a-checkbox-group :options="serverTypeOps" v-model="form.service_types">
                  <span slot="label" slot-scope="option">{{ option.name }}</span>
                </a-checkbox-group>
              </a-form-model-item>
            </a-col>
            <a-col :xxl="4" :lg="5" :md="6">
              <a-form-model-item style="margin-top:24px">
                <a-checkbox-group v-model="dangerAndoverWeight">
                  <a-checkbox value="danger">危险品</a-checkbox>
                  <a-checkbox value="overweight">超重</a-checkbox>
                </a-checkbox-group>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row :gutter="20" v-if="form.service_types.indexOf(6) > -1">
            <a-col v-bind="colSpan">
              <a-form-model-item label="货值">
                <a-input v-model="form.value"/>
              </a-form-model-item>
            </a-col>
            <a-col v-bind="colSpan">
              <a-form-model-item label="保费">
                <a-input v-model="form.premium"/>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row :gutter="20" v-if="dangerAndoverWeight.indexOf('danger') > -1">
            <a-col v-bind="colSpan">
              <a-form-model-item label="UN NO.">
                <a-input v-model="form.un_no"/>
              </a-form-model-item>
            </a-col>
            <a-col v-bind="colSpan">
              <a-form-model-item label="CLASS NO.">
                <a-input v-model="form.class_no"/>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row :gutter="20">
            <a-col v-bind="colSpan">
              <a-form-model-item label="订舱代理">
                <a-select
                  showSearch
                  allowClear
                  placeholder="请选择订舱代理"
                  style="width: 100%"
                  ref="agent"
                  v-model="form.agent"
                >
                  <a-select-option v-for="op in agentOps" :key="op.value">{{ op.name }}</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col v-bind="colSpan" v-if="form.trans_type == 1">
              <a-form-model-item label="班列公司">
                <a-input v-model="form.trans_company"/>
              </a-form-model-item>
            </a-col>
            <a-col v-bind="colSpan" v-if="form.trans_type == 1">
              <a-form-model-item label="合约号">
                <a-input v-model="form.deal_num"/>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row :gutter="20">
            <a-col v-bind="colSpan">
              <a-form-model-item label="国外代理">
                <a-select
                  showSearch
                  allowClear
                  placeholder="请选择国外代理"
                  style="width: 100%"
                  ref="agent_aboard"
                  v-model="form.agent_aboard"
                >
                  <a-select-option v-for="op in agentAboardOps" :key="op.value">{{ op.name }}</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-card>
        <a-card class="card pc" title="配舱信息" :bordered="false">
          <a-drawer
            title="加拼管理"
            placement="left"
            :visible="visible"
            width="500px"
            :getContainer="false"
            @close="visible = false"
            :wrapStyle="{ position: 'absolute' }"
          >
            <edit-table
              :columns="orderColumns" :sourceData="orderData"
              @getTableDate="getOrderData"></edit-table>
          </a-drawer>
          <a-row :gutter="20">
            <a-col v-bind="colSpan">
              <a-form-model-item label="上货站">
                <a-input v-model="form.departure" />
              </a-form-model-item>
            </a-col>
            <a-col v-bind="colSpan">
              <a-form-model-item label="下货站">
                <a-input v-model="form.destination" />
              </a-form-model-item>
            </a-col>
            <a-col v-bind="colSpan">
              <a-form-model-item label="出入境口岸">
                <a-select
                  showSearch
                  allowClear
                  placeholder="请选择出入境口岸"
                  style="width: 100%"
                  v-model="form.port"
                >
                  <a-select-option v-for="op in portOps" :key="op.value">{{ op.name }}</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row :gutter="20" v-if="form.trans_type == 1">
            <a-col :xxl="18" :lg="21" :md="24">
              <a-form-model-item label="箱型箱量">
                <template v-for="(box, index) in form.box_info">
                  <!-- <a-input-group compact :key="index" class="box-item">
                    <a-select
                      showSearch
                      placeholder="请选择箱型"
                      style="width: 120px"
                      v-model="box.id"
                    >
                      <a-select-option v-for="op in boxOps" :key="op.value">{{ op.name }}</a-select-option>
                    </a-select>
                    <a-input-number v-model="box.num" style="width: 80px"/>
                    <a-icon type="close-circle" theme="filled"
                            class="box-item_del" v-if="index > 0"
                            @click="delItem('box_info', index)"/>
                    <a-button type="primary" v-if="index === (form.box_info.length - 1)"
                              style="width: 60px;margin-left: 10px" @click="createItem('box_info')">更多</a-button>
                  </a-input-group> -->
                  <a-input-group compact :key="index" class="field-item box-item">
                    <a-select
                      showSearch
                      placeholder="箱型"
                      style="width: 90px"
                      v-model="box.id"
                    >
                      <a-select-option v-for="op in boxOps" :key="op.value">{{ op.name }}</a-select-option>
                    </a-select>
                    <a-input v-model="box.box_num" style="width: 120px" placeholder="箱号"/>
                    <a-input-number v-model="box.num" style="width: 100px" placeholder="箱量"/>
                    <a-icon type="close-circle" theme="filled"
                            class="field-item_del" v-if="index > 0"
                            @click="delItem('box_info', index)"/>
                    <a-button type="primary" v-if="index === (form.box_info.length - 1)"
                              style="width: 60px;margin-left: 10px" @click="createItem('box_info')">更多</a-button>
                  </a-input-group>
                </template>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row :gutter="20">
            <a-col v-bind="colSpan">
              <a-form-model-item label="班列日期">
                <a-date-picker v-model="form.trans_date" class="full-width"/>
              </a-form-model-item>
            </a-col>
            <a-col v-bind="colSpan">
              <a-form-model-item label="往返">
                <a-select
                  showSearch
                  allowClear
                  style="width: 100%"
                  v-model="form.dest_type"
                >
                  <a-select-option :key="1">东向</a-select-option>
                  <a-select-option :key="2">西向</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col v-bind="colSpan">
              <a-form-model-item label="班列号">
                <a-input v-model="form.blh" />
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row :gutter="20">
            <a-col v-bind="colSpan">
              <a-form-model-item label="主单号">
                <a-input v-model="form.order_num" />
              </a-form-model-item>
            </a-col>
            <a-col v-bind="colSpan">
              <a-button type="link" icon="plus-circle" style="width: 60px;margin-top: 24px" @click="handleAddOrder">加拼</a-button>
            </a-col>
          </a-row>
        </a-card>
        <a-card class="card" title="提单信息" :bordered="false">
          <a-row :gutter="20">
            <a-col v-bind="colSpan">
              <a-form-model-item label="发货人">
                <a-input v-model="form.sender" />
              </a-form-model-item>
            </a-col>
            <a-col v-bind="colSpan">
              <a-form-model-item label="收货人">
                <a-input v-model="form.receiver" />
              </a-form-model-item>
            </a-col>
            <a-col v-bind="colSpan">
              <a-form-model-item label="通知人">
                <a-input v-model="form.reminders" />
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row :gutter="20">
            <a-col v-bind="colSpan">
              <a-form-model-item label="国外代理">
                <a-input v-model="form.agent_bu_aboard" />
              </a-form-model-item>
            </a-col>
            <a-col v-bind="colSpan">
              <a-form-model-item label="中文品名">
                <a-input v-model="form.name" />
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row :gutter="20">
            <a-col :xxl="12" :lg="14" :md="16">
              <a-form-model-item label="英文品名">
                <template v-for="(item, index) in form.eng_name">
                  <a-input-group compact :key="index" class="field-item eng-name-item">
                    <a-input v-model="form.eng_name[index]" style="width: calc(100% - 100px)"/>
                    <a-icon type="close-circle" theme="filled"
                            class="field-item_del" v-if="form.eng_name.length > 1"
                            @click="delItem('eng_name', index)"/>
                    <a-button type="primary" v-if="index === (form.eng_name.length - 1)"
                              style="width: 60px;margin-left: 10px" @click="createItem('eng_name')">添加</a-button>
                  </a-input-group>
                </template>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row :gutter="20">
            <a-col v-bind="colSpan">
              <a-form-model-item label="国内HS CODE">
                <a-input v-model="form.hs_code_in" />
              </a-form-model-item>
            </a-col>
            <a-col v-bind="colSpan">
              <a-form-model-item label="国外HS CODE">
                <a-input v-model="form.hs_code_out" />
              </a-form-model-item>
            </a-col>
            <a-col v-bind="colSpan">
              <a-form-model-item label="唛头">
                <a-input v-model="form.mai" />
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row :gutter="20">
            <a-col v-bind="colSpan">
              <a-form-model-item label="外包装总件数">
                <a-input-group compact>
                  <a-input-number style="width: 40%" v-model="form.total_num"/>
                  <a-auto-complete
                    v-model="form.unit"
                    style="width: 60%"
                    placeholder="请选择包装方式"
                    :filterOption="handleAutoSearch"
                  >
                    <template slot="dataSource">
                      <a-select-option v-for="op in packageTypeOps" :key="op.name">{{ op.name }}</a-select-option>
                    </template>
                  </a-auto-complete>
                </a-input-group>
              </a-form-model-item>
            </a-col>
            <a-col v-bind="colSpan">
              <a-form-model-item label="总毛重">
                <a-input suffix="KGS" v-model="form.weight"/>
              </a-form-model-item>
            </a-col>
            <a-col v-bind="colSpan">
              <a-form-model-item label="总体积">
                <a-input suffix="m³" v-model="form.volume"/>
              </a-form-model-item>
            </a-col>
            <a-col v-bind="colSpan">
              <a-form-item label="总天数">
                <a-input v-model="form.total_day"/>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row :gutter="20">
            <a-col v-bind="colSpan">
              <a-form-model-item label="规格">
                <a-input suffix="L*W*H" v-model="form.standard"/>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row :gutter="20">
            <a-col v-bind="colSpan">
              <a-form-model-item label="付款方式">
                <a-select
                  showSearch
                  allowClear
                  placeholder="请选择付款方式"
                  style="width: 100%"
                  v-model="form.pay_type"
                >
                  <a-select-option v-for="op in payTypeOps" :key="op.value">{{ op.name }}</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col v-bind="colSpan">
              <a-form-model-item label="运输条款">
                <a-auto-complete
                  v-model="form.trans_term"
                  style="width: 100%"
                  placeholder="请选择运输条款"
                  :filterOption="handleAutoSearch"
                >
                  <template slot="dataSource">
                    <a-select-option v-for="op in transTermOps" :key="op.name">{{ op.name }}</a-select-option>
                  </template>
                </a-auto-complete>
              </a-form-model-item>
            </a-col>
            <a-col v-bind="colSpan">
              <a-form-model-item label="提单形式">
                <a-select
                  showSearch
                  allowClear
                  placeholder="请选择提单形式"
                  style="width: 100%"
                  v-model="form.lading_bill_type"
                >
                  <a-select-option v-for="op in ladingBillOps" :key="op.value">{{ op.name }}</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-card>
        <a-row :gutter="20">
          <a-col :span="12">
            <a-card class="card" title="订舱备注" :bordered="false">
              <a-form-model-item label="">
                <a-textarea v-model="form.order_remark" />
              </a-form-model-item>
            </a-card>
          </a-col>
          <a-col :span="12">
            <a-card class="card" title="配舱备注" :bordered="false">
              <a-form-model-item label="">
                <a-textarea v-model="form.match_remark" />
              </a-form-model-item>
            </a-card>
          </a-col>
        </a-row>
        <a-card class="card" title="费用信息" :bordered="false">
          <a-tabs default-active-key="a">
            <a-tab-pane key="a" tab="应收费用">
              <edit-table
                scroll
                :columns="chargeFeeColumns"
                :sourceData="chargeFeeData"
                :feeStatusFlag="true"
                @getTableDate="getChargeFeeData"
                @getTableDateError="getTableDateError"></edit-table>
            </a-tab-pane>
            <a-tab-pane key="b" tab="应付费用">
              <edit-table
                scroll
                ref="order_pay_fee"
                :columns="payFeeColumns"
                :sourceData="payFeeData"
                :feeStatusFlag="true"
                @getTableDate="getPayFeeData"
                @getTableDateError="getTableDateError"></edit-table>
            </a-tab-pane>
            <template slot="tabBarExtraContent">
              <template>
                <span style="margin-right: 20px">应收：{{ totalChargeFee.toFixed(2) }} </span>
                <span style="margin-right: 20px"> 应付：{{ totalPayFee.toFixed(2) }} </span>
                <span style="margin-right: 100px"> 利润：{{ (totalChargeFee - totalPayFee).toFixed(2) }} </span>
                <!--<a-button type="primary" :disabled="!isEdit">
                  现金收费
                </a-button>-->
              </template>
            </template>
          </a-tabs>
        </a-card>
      </a-form-model>
    </div>
  </a-spin>
</template>

<script>
import { getCommonOptions, getCustomer, getSupplier } from '@/api/common'
import { createItmsOrderInfo, getItmsOrderDetail, getItmsMetaOption, getItmsOrderCopyInfo } from '@/api/itms'
import { EditTable } from '@/components'
import moment from 'moment';
import preventReClick from '@/utils/preventReClick' // 防多次点击，重复提交
import debounce from 'lodash/debounce';
export default {
  components: {
    EditTable, preventReClick
  },
  data() {
    this.lastFetchId = 0;
    this.getDepartmentCustomer = debounce(this.getDepartmentCustomer, 800);
    return {
      colSpan: {
        xxl: 6,
        lg: 7,
        md: 8
      },
      customerInfo: undefined,
      fetching: false,
      spinning: false,
      visible: false,
      disabled: false,
      isEdit: false,
      form: {},
      blh: '', // 处理班列号
      sourceForm: {
        id: '',
        serial_num: '',
        quota_num: '',
        auth_agency: null,
        customer_num: '',
        trade_term: '',
        trans_type: 1,
        service_types: [],
        value: '',
        premium: '',
        un_no: '',
        class_no: '',
        agent: null,
        trans_company: '',
        deal_num: '',
        agent_aboard: null,
        // 配舱信息
        departure: '',
        destination: '',
        port: null, // 出入境口岸
        box_info: [{ id: '', num: null }],
        trans_date: null,
        dest_type: 1,
        trans_num: [], // 班列号
        order_num: '',
        custom_num: [],
        // 提单信息
        sender: '',
        receiver: '',
        reminders: '',
        agent_bu_aboard: '',
        name: '', // 中文品名
        eng_name: [''], // 英文品名
        hs_code_in: '',
        hs_code_out: '',
        mai: '',
        total_num: null,
        unit: '',
        weight: null,
        volume: null,
        total_day: '',
        standard: '',
        pay_type: null,
        trans_term: '',
        lading_bill_type: null,
        order_remark: '',
        match_remark: ''
      },
      dangerAndoverWeight: [], // 危险品超重
      rules: {
        // 报价编号
        quota_num: [{ required: true, message: '请输入报价编号', trigger: 'blur' }],
        // 服务类型
        service_types: [{ required: true, message: '请选择服务类型', trigger: 'blur' }]
      },
      orderColumns: [
        {
          title: '加拼提单号',
          dataIndex: 'custom_num'
        }
      ],
      orderData: [],
      agencyOps: [],
      agentOps: [], // 订舱代理
      agentAboardOps: [],
      portOps: [],
      tradeTermOps: [],
      payTypeOps: [{ value: 1, name: 'FREIGHT_PREPAID' }, { value: 2, name: 'FREIGHT_COLLECT' }],
      transTermOps: [{ value: 1, name: 'CY-CY' }],
      ladingBillOps: [{ value: 1, name: '正本' }, { value: 2, name: '电放' }, { value: 3, name: 'SEAWAY' }],
      boxOps: [], // 箱型
      packageTypeOps: [],
      serverTypeOps: [
        { value: 1, name: '订舱' },
        { value: 2, name: '拖车' },
        { value: 3, name: '内装' },
        { value: 4, name: '报关' },
        { value: 5, name: '海外段' },
        { value: 6, name: '保险' },
        { value: 7, name: '租箱' },
        { value: 8, name: '熏蒸' },
        { value: 9, name: '买单' },
        { value: 10, name: '办证' },
        { value: 11, name: '制单' }
      ],
      chargeFeeData: [],
      payFeeData: [],
      chargeFeeColumns: [
        {
          title: '费用名称',
          dataIndex: 'fee_name_type',
          extra: {
            type: 'select',
            func: getCommonOptions,
            params: ['fee', { department: 2, type: 1 }],
            options: [],
            disabled: record => {
              return record.disabled
            }
          }
        },
        {
          title: '类型',
          dataIndex: 'fee_type',
          extra: {
            defaultValue: 1,
            hidden: true,
            disabled: record => {
              return record.disabled
            }
          }
        },
        {
          title: '金额',
          dataIndex: 'money',
          extra: {
            extraFunc: this.calcTotalCharge,
            disabled: record => {
              return record.disabled
            }
          }
        },
        {
          title: '币种',
          dataIndex: 'currency',
          extra: {
            type: 'select',
            defaultValue: 19,
            func: getCommonOptions,
            params: ['currency_type'],
            options: [],
            disabled: record => {
              return record.disabled
            }
          }
        },
        {
          title: '发票号',
          dataIndex: 'invoice_num',
          extra: {
            disabled: record => {
              return record.disabled
            }
          }
        },
        {
          title: '费用状态',
          dataIndex: 'fee_status',
          customRender: (text, record) => {
            if (this.GLOBAL.feeStatusMaps[text] && record.deny_reason) {
              return this.GLOBAL.feeStatusMaps[text].name + `:${record.deny_reason}`
            } else if (this.GLOBAL.feeStatusMaps[text]) {
              return this.GLOBAL.feeStatusMaps[text].name
            }
          },
          extra: {
            defaultValue: 1,
            disabled: record => {
              return record.disabled
            }
          }
        },
        {
          title: '是否预收',
          dataIndex: 'is_advance',
          extra: {
            type: 'select',
            options: [
              { name: '非预收', value: 1 },
              { name: '预收', value: 2 }
            ],
            defaultValue: 1,
            disabled: record => {
              return record.disabled
            }
          }
        }
      ],
      payFeeColumns: [
        {
          title: '费用名称',
          dataIndex: 'fee_name_type',
          extra: {
            type: 'select',
            func: getCommonOptions,
            params: ['fee', { department: 2, type: 2 }],
            options: [],
            disabled: record => {
              return record.disabled
            }
          }
        },
        {
          title: '类型',
          dataIndex: 'fee_type',
          extra: {
            defaultValue: 2,
            hidden: true,
            disabled: record => {
              return record.disabled
            }
          }
        },
        {
          title: '金额',
          dataIndex: 'money',
          width: 120,
          extra: {
            extraFunc: this.calcTotalPay,
            disabled: record => {
              return record.disabled
            }
          }
        },
        {
          title: '币种',
          dataIndex: 'currency',
          width: 80,
          extra: {
            type: 'select',
            defaultValue: 19,
            func: getCommonOptions,
            params: ['currency_type'],
            options: [],
            disabled: record => {
              return record.disabled
            }
          }
        },
        {
          title: '付款对象',
          dataIndex: 'pay_target_id',
          extra: {
            type: 'select',
            func: getSupplier,
            params: [ { department: 2 } ],
            options: [],
            disabled: record => {
              return record.disabled
            }
          }
        },
        {
          title: '费用状态',
          dataIndex: 'fee_status',
          customRender: (text, record) => {
            if (this.GLOBAL.feeStatusMaps[text] && record.deny_reason) {
              return this.GLOBAL.feeStatusMaps[text].name + `:${record.deny_reason}`
            } else if (this.GLOBAL.feeStatusMaps[text]) {
              return this.GLOBAL.feeStatusMaps[text].name
            }
          },
          extra: {
            defaultValue: 1,
            disabled: record => {
              return record.disabled
            }
          }
        },
        {
          title: '是否预付',
          dataIndex: 'is_advance',
          extra: {
            type: 'select',
            options: [
              { name: '非预付', value: 1 },
              { name: '预付', value: 2 }
            ],
            defaultValue: 1,
            disabled: record => {
              return record.disabled
            }
          }
        }
      ],
      // 应收金额统计展示
      totalChargeFee: 0,
      // 应付金额统计展示
      totalPayFee: 0
    }
  },
   watch: {
    $route: function(newRoute) {
      this.disabled = false
      if (!this.queryParam && newRoute.name === 'RailwayCUR') { // 编辑页路由监控
        this.handleFormReset()
        if (newRoute.params.id === 'create' || newRoute.query.isCopy) { // 新增
        if (newRoute.query.isCopy) {
            this.getEditData(newRoute.params.id, true)
          } else {
            this.isEdit = false
            this.$route.meta.title = '新增铁路运输订单'
            this.$refs.form.clearValidate()
          }
        } else {
          this.disabled = newRoute.query.disabled
          this.getEditData(newRoute.params.id)
        }
      }
    }
  },
  created() {
    this.handleFormReset()
    this.getBasicData()
    if (this.$route.params.id !== 'create') {
      this.disabled = this.$route.query.disabled !== 'false' && this.$route.query.disabled
      this.getEditData(this.$route.params.id, this.$route.query.isCopy)
    }
  },
  methods: {
    getTableDateError() {
      this.$notification['error']({
        message: '提示',
        description: '该订单不可删除！'
      })
    },
    moment,
    handleFormReset() {
      for (const key in this.sourceForm) {
        this.$set(this.form, key, this.sourceForm[key])
      }
      this.customerInfo = undefined
      this.chargeFeeData = []
      this.payFeeData = []
      this.totalChargeFee = 0
      this.totalPayFee = 0
    },
    getEditData(id, isCopy) {
      this.isEdit = true
      this.spinning = true
      this.$route.meta.title = isCopy ? '新增铁路运输订单' : '编辑铁路运输订单'
      let func = getItmsOrderDetail
      if (isCopy) {
        func = getItmsOrderCopyInfo
      }
      func({ id: id }).then(v => {
        if (v.order.auth_agency) {
          this.handleAgencyChange(v.order.auth_agency)
        }
        this.form = {
          ...v.order,
          ...v.content,
          id: v.order.id,
          trans_date: v.order['trans_date'] ? moment(v.order['trans_date'], 'YYYY/MM/DD') : null,
          box_info: v.order['box_info'] || [{ id: '', num: null }],
          trans_num: v.content['trans_num'] || [],
          custom_num: v.order['custom_num'] || [],
          eng_name: v.content.eng_name || this.sourceForm.eng_name
        }
        v.charge_fees.forEach(fee => {
          if ((fee.fee_status > 2 && fee.fee_status !== 4) || (fee.is_balance === 2 && fee.fee_status !== 4) || (fee.is_payment === 2 && fee.fee_status !== 4)) fee.disabled = true
        })
        v.pay_fees.forEach(fee => {
          if ((fee.fee_status > 2 && fee.fee_status !== 4) || (fee.is_balance === 2 && fee.fee_status !== 4) || (fee.is_payment === 2 && fee.fee_status !== 4)) fee.disabled = true
        })
        this.customerInfo = {
          key: v.order.auth_agency,
          label: v.order.agency_name
        }
        this.blh = this.form.trans_num && this.form.trans_num[0] || ''
        this.chargeFeeData = v.charge_fees
        this.chargeFeeData.forEach(v => {
          this.totalChargeFee += parseFloat(v.money);
        });
        this.payFeeData = v.pay_fees
        this.payFeeData.forEach(v => {
          this.totalPayFee += parseFloat(v.money);
        });
        this.orderData = v.order.custom_num && v.order.custom_num.map(c => {
          return { custom_num: c }
        }) || []
        this.spinning = false
      }).catch(_ => {
        this.spinning = false
      })
    },
    getDepartmentCustomer(value) {
      this.lastFetchId += 1;
      const fetchId = this.lastFetchId;
      this.agencyOps = [];
      this.fetching = true;
      getCustomer({
        department: 2,
        customerName: value,
        excludeBlack: 'Y'
      }).then(res => {
        if (fetchId !== this.lastFetchId) {
          return;
        }
        this.agencyOps = res
        this.fetching = false;
      })
    },
    changeCustomerInfo(value) {
      this.customerInfo = value
      this.form['auth_agency'] = value.key
      this.form['agency_name'] = value.label
      this.agencyOps = []
      this.fetching = false
    },
    getBasicData() {
      getCommonOptions('box_info').then(v => {
        this.boxOps = v
      })
      getItmsMetaOption('itms_role', { role_type: 1 }).then(v => {
        this.agentOps = v
      })
      getItmsMetaOption('itms_role', { role_type: 2 }).then(v => {
        this.agentAboardOps = v
      })
      // getCommonOptions('customer').then(v => {
      //   this.agencyOps = v
      // })
      getCommonOptions('common_type', { db_type: 3 }).then(v => {
        this.packageTypeOps = v
      })
      // 贸易条款
      getItmsMetaOption('itms_trans_com', { type: 9 }).then(v => {
        this.tradeTermOps = v
      })
      // 口岸
      getItmsMetaOption('itms_trans_com', { type: 8 }).then(v => {
        this.portOps = v
      })
      // 付款方式
      getItmsMetaOption('itms_trans_com', { type: 6 }).then(v => {
        this.payTypeOps = v
      })
      // 运输条款
      getItmsMetaOption('itms_trans_com', { type: 11 }).then(v => {
        this.transTermOps = v
      })
      // 提单方式
      getItmsMetaOption('itms_trans_com', { type: 10 }).then(v => {
        this.ladingBillOps = v
      })
    },
    handleAgencyChange(val) {
      // getCustomerContactList({ id: val }).then(v => {
      //   this.reminderOps = v
      // })
    },
    createItem(field) { // 增加更多箱型
      if (field === 'box_info') {
        this.form.box_info.push({ id: null, num: null, box_num: '' })
      } else if (field === 'send_info') {
        this.form.send_info.push({ send_date: null, send_address: '' })
      } else {
        this.form[field].push('')
      }
    },
    delItem(field, index) {
      this.form[field].splice(index, 1)
    },
    handleAddOrder() { // 加拼
      this.visible = true
    },
    getOrderData(data) {
      this.orderData = data
    },
    getChargeFeeData(data) {
      this.chargeFeeData = data
    },
    getPayFeeData(data) {
      this.payFeeData = data
    },
    calcTotalCharge(value, newData) {
      let money = 0;
      this.chargeFeeData.forEach(v => {
        money += parseFloat(v.money)
      });
      this.totalChargeFee = money;
      return newData;
    },
    calcTotalPay(value, newData) {
      let money = 0;
      this.payFeeData.forEach(v => {
        money += parseFloat(v.money)
      });
      this.totalPayFee = money;
      return newData
    },
    handleAutoSearch(inputValue, option) {
      if (inputValue) {
        return option.key.toLowerCase().includes(inputValue.toLowerCase())
      } else {
        return true
      }
    },
    handleSubmit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          const orderKeys = ['id', 'serial_num', 'quota_num', 'auth_agency', 'agency_name', 'customer_num', 'trade_term', 'trans_type', 'service_types', 'custom_num',
            'value', 'premium', 'un_no', 'class_no', 'agent', 'agent_name', 'trans_company', 'deal_num', 'agent_aboard', 'agent_aboard_name', 'order_num', 'box_info']
          const contentKeys = ['departure', 'destination', 'port', 'trans_date', 'trans_num',
            'sender', 'receiver', 'reminders', 'agent_bu_aboard', 'name', 'eng_name', 'hs_code_in', 'hs_code_out', 'mai', 'eng_name', 'total_num', 'unit', 'weight', 'total_day', 'volume',
            'standard', 'pay_type', 'trans_term', 'lading_bill_type', 'order_remark', 'match_remark']
          const tempData = {
            ...this.form,
            trans_num: this.blh ? [this.blh] : [],
            custom_num: this.orderData.map(v => {
              return v.custom_num
            }),
            trans_date: this.form.trans_date ? moment(this.form.trans_date).valueOf() : null,
            agent_name: this.form.agent ? this.$refs['agent'].$el.innerText : '',
            agent_aboard_name: this.form.agent_aboard ? this.$refs['agent_aboard'].$el.innerText : ''
          }
          const orderData = {}
          const contentData = {}
          for (const key in tempData) {
            if (orderKeys.includes(key)) {
              orderData[key] = tempData[key]
            }
            if (contentKeys.includes(key)) {
              contentData[key] = tempData[key]
            }
          }

          const data = {
            order: orderData,
            content: contentData,
            charge_fees: this.chargeFeeData,
            pay_fees: this.payFeeData
          }
          createItmsOrderInfo('save_railway', data).then(_ => {
              this.$notification['success']({
                message: '提示',
                description: this.isEdit ? '编辑成功' : '创建成功.'
              })
              this.$router.push({
                name: 'Railway',
                params: {
                  order: orderData,
                  content: contentData
                },
                query: {
                  isEdit: this.isEdit
                }
              })
            })
        } else {
          return false;
        }
      })
    }
  }
}
</script>

<style>
  .pc .ant-drawer{
    display: none;
  }
  .pc .ant-drawer-open{
    display: block;
  }
</style>

<style scoped>
  .card {
    margin-bottom:10px;
  }
  .table-title{
    margin-bottom: 10px;
    display: inline-block;
    font-weight: 800;
    /* color: #1890ff */
  }
  .field-item{
    position: relative;
    width:200px;
    margin-right: 10px;
    margin-top: 10px;
    color: red;
  }
  .field-item:last-child{
    width: 280px;
  }
  .field-item_del{
    position: absolute;
    top: -5px;
    left: 190px;
    color: #ccc;
    z-index: 999;
    cursor: pointer;
  }
  .field-item_del:hover, .send-item_del:hover{
    color: #1890ff;
  }
  .box-item{
    width:310px;
  }
  .box-item:last-child{
    width: 410px;
  }
  .box-item  .field-item_del{
    left: 300px;
  }
  .eng-name-item{
    width:100%;
  }
  .eng-name-item:last-child{
    width: 100%;
  }
  .eng-name-item  .field-item_del{
    left: calc(100% - 110px);
  }
</style>
